import React from "react"

// UI
import Layout from "~components/layout"
import SEO from "~components/seo"
import PlatformHero from "~content/platform/Hero"
import PlatformTopFeatures from "~content/platform/TopFeatures"
import PlatformFeaturesList from "~content/platform/FeaturesList"
// import PlatformExtraFeatures from "~content/platform/ExtraFeaatures"
import PlatformTrust from "~content/platform/Trust"
import PlatformTestimonial from "~content/platform/Testimonial"

const PlatformPage: React.FC = () => {
  return (
    <Layout>
      <SEO
        canonicalTag="https://canopyservicing.com/platform/"
        title="Platform Overview | Canopy Servicing"
        description="Canopy Servicing provides APIs to banks and Fintechs to manage payment cards, Buy Now Pay Later programs, and other lending products."
      />
      <PlatformHero />
      <PlatformTopFeatures />
      <PlatformFeaturesList />
      {/* <PlatformExtraFeatures /> */}
      <PlatformTestimonial />
      <PlatformTrust />
    </Layout>
  )
}

export default PlatformPage
