import React from "react"

// UI
import SectionHero from "~components/SectionHero"
import SectionMediaFull from "~components/SectionMediaFull"
import Spirograph from "~components/artwork/Spirograph"
import Aura from "~components/artwork/Aura"

// Media
import imgCustomersDetail from "~images/platform/preview-customers-detail.png"

const PlatformHero: React.FC = () => (
  <>
    <SectionHero
      title={
        <>
          Innovate. Launch.
          <br /> Scale. <span className="text-blue-500">Repeat.</span>
        </>
      }
      subtitle="One platform to manage and service all your debit, credit, and lending products."
      buttons={false}
    />

    <Spirograph
      shape={4}
      width="w-full sm:w-3/4 md:w-3/4 xl:w-3/5"
      position="right-0"
      offsetY="-translate-y-1/2 sm:-translate-y-3/20 md:-translate-y-1/4"
      offsetX="translate-x-1/2 sm:translate-x-1/3 md:translate-x-2/5"
    />
    <Aura
      color="purple"
      size="w-full2x lg:w-full"
      position="left-0"
      offsetY="-translate-y-2/5"
      offsetX="-translate-x-1/2"
      opacity="opacity-20"
    />
    <Aura
      size="w-full2x sm:w-full1/2x md:w-full"
      position="right-0"
      offsetY="-translate-y-1/2 sm:-translate-y-1/3"
      offsetX="translate-x-1/2"
      opacity="opacity-50"
    />
    <Aura
      color="purple"
      size="w-full1/2x md:w-full"
      position="right-0"
      offsetY="-translate-y-3/20 md:-translate-y-1/20"
      offsetX="translate-x-1/2 md:translate-x-3/5"
      opacity="opacity-30"
    />

    <SectionMediaFull img={imgCustomersDetail} className="mb-20 md:mb-24 lg:mb-32 xl:mb-40" />
  </>
)

export default PlatformHero
