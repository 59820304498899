import React from "react"

// UI
import Headline from "~ui/Headline"
import SectionThreeCols from "~components/SectionThreeCols"
import SectionTwoCols, { TwoColCard } from "~components/SectionTwoCols"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import {
  faListTree,
  faCreditCard,
  faPlug,
  faHandHoldingDollar,
  faFileInvoice,
  faMagicWandSparkles,
  faFileSpreadsheet,
} from "@fortawesome/pro-thin-svg-icons"

import imgImmutable from "~images/platform/immutable.png"
import imgPaymentProcessing from "~images/platform/payment-processing.png"
import imgCardIssuing from "~images/platform/card-issuing.png"
import imgReporting from "~images/platform/reporting.png"
import imgStatements from "~images/platform/statements.png"
import imgIntegrations from "~images/platform/integrations.png"
import imgAutomations from "~images/platform/automations.png"

const PlatformFeaturesList: React.FC = () => (
  <>
    <SectionThreeCols title="Launch and Scale Innovative Financial Products on Canopy" />

    <SectionTwoCols className="mb-16 md:mb-24 lg:mb-40 sm:mt-8 md:mt-16 lg:mt-24">
      <TwoColCard data-sal="slide-up" data-sal-delay="100">
        <div className="mb-6 md:mb-12">
          <Icon icon={faListTree} size="4x" className="text-blue-500" />
        </div>
        <Headline level={3} className="mb-6 md:mb-8">
          Immutable Ledger
        </Headline>
        <p className="md:text-lg">
          Our immutable database tracks every change to your ledger, supports real-time calculations, and enables
          dynamic retroactive events.
        </p>
      </TwoColCard>
      <TwoColCard className="bg-blue-300 dark:bg-blue-900 md:order-first" data-sal="slide-up">
        <img src={imgImmutable} alt="Payment Processing" data-sal="slide-up" data-sal-delay="300" />
      </TwoColCard>
    </SectionTwoCols>

    <SectionTwoCols className="mb-16 md:mb-24 lg:mb-40">
      <TwoColCard data-sal="slide-up">
        <div className="mb-6 md:mb-12">
          <Icon icon={faCreditCard} size="4x" className="text-blue-500" />
        </div>
        <Headline level={3} className="mb-6 md:mb-8">
          Card Issuing + Network Processing
        </Headline>
        <p className="md:text-lg">
          Our low code integrations with modern issuing platforms effortlessly streamlines issuing, processing, and
          servicing.
        </p>
      </TwoColCard>
      <TwoColCard className="bg-purple-300 dark:bg-purple-900" data-sal="slide-up" data-sal-delay="100">
        <img
          src={imgCardIssuing}
          alt="Payment Processing"
          className="filter drop-shadow-xl"
          data-sal="slide-up"
          data-sal-delay="300"
        />
      </TwoColCard>
    </SectionTwoCols>

    <SectionTwoCols className="mb-16 md:mb-24 lg:mb-40">
      <TwoColCard data-sal="slide-up" data-sal-delay="100">
        <div className="mb-6 md:mb-12">
          <Icon icon={faHandHoldingDollar} size="4x" className="text-blue-500" />
        </div>
        <Headline level={3} className="mb-6 md:mb-8">
          Payment Processing
        </Headline>
        <p className="md:text-lg">
          Whether you are lending to consumers or to businesses, offering revolving credit or debit cards, BNPL,
          installment loans, or lines of credit, we’ve got you covered.
        </p>
      </TwoColCard>
      <TwoColCard className="bg-blue-100 dark:bg-blue-500 md:order-first" data-sal="slide-up">
        <img
          src={imgPaymentProcessing}
          alt="Payment Processing"
          className="filter drop-shadow-xl"
          data-sal="slide-up"
          data-sal-delay="300"
        />
      </TwoColCard>
    </SectionTwoCols>

    <SectionTwoCols className="mb-16 md:mb-24 lg:mb-40">
      <TwoColCard data-sal="slide-up">
        <div className="mb-6 md:mb-12">
          <Icon icon={faFileInvoice} size="4x" className="text-blue-500" />
        </div>
        <Headline level={3} className="mb-6 md:mb-8">
          Statements
        </Headline>
        <p className="md:text-lg">
          Real-time account statement data — pull from the statements API endpoint anytime to populate your UI or send
          to a PDF generation service.
        </p>
      </TwoColCard>
      <TwoColCard className="bg-blue-400 dark:bg-blue-900" data-sal="slide-up" data-sal-delay="100">
        <img
          src={imgStatements}
          alt="Statements"
          className="filter drop-shadow-xl"
          data-sal="slide-up"
          data-sal-delay="300"
        />
      </TwoColCard>
    </SectionTwoCols>

    <SectionTwoCols className="mb-16 md:mb-24 lg:mb-40">
      <TwoColCard data-sal="slide-up" data-sal-delay="100">
        <div className="mb-6 md:mb-12">
          <Icon icon={faMagicWandSparkles} size="4x" className="text-blue-500" />
        </div>
        <Headline level={3} className="mb-6 md:mb-8">
          Automate All the Events With Webhooks
        </Headline>
        <p className="md:text-lg">
          Leverage Canopy&#39;s robust webhooks to seamlessly automate nearly every aspect of your customer&#39;s loan
          lifecycle or your internal team&#39;s unique support workflows.
        </p>
      </TwoColCard>
      <TwoColCard className="bg-blue-100 dark:bg-blue-900 md:order-first" data-sal="slide-up">
        <img
          src={imgAutomations}
          alt="Automations"
          className="filter drop-shadow-xl"
          data-sal="slide-up"
          data-sal-delay="300"
        />
      </TwoColCard>
    </SectionTwoCols>

    <SectionTwoCols className="mb-16 md:mb-24 lg:mb-40">
      <TwoColCard data-sal="slide-up" data-sal-delay="100">
        <div className="mb-6 md:mb-12">
          <Icon icon={faFileSpreadsheet} size="4x" className="text-blue-500" />
        </div>
        <Headline level={3} className="mb-6 md:mb-8">
          Take Control of Your Reporting
        </Headline>
        <p className="md:text-lg">
          Easily satisfy the unique reporting requirements for your bank partners, credit bureaus, and collection
          agencies with DataDirect. Our data-rich SQL business intelligence product.
        </p>
      </TwoColCard>
      <TwoColCard className="bg-purple-300 dark:bg-purple-900" data-sal="slide-up">
        <img
          src={imgReporting}
          alt="Reporting"
          className="filter drop-shadow-xl"
          data-sal="slide-up"
          data-sal-delay="300"
        />
      </TwoColCard>
    </SectionTwoCols>

    <SectionTwoCols className="mb-16 md:mb-24 lg:mb-40">
      <TwoColCard data-sal="slide-up">
        <div className="mb-6 md:mb-12">
          <Icon icon={faPlug} size="4x" className="text-blue-500" />
        </div>
        <Headline level={3} className="mb-6 md:mb-8">
          Best-in-class Integrations
        </Headline>
        <p className="md:text-lg">
          Scale faster with your dream stack using our powerful low code integrations. We work with the best-of-class
          providers to enable the most innovative products.
        </p>
      </TwoColCard>
      <TwoColCard className="bg-blue-100 dark:bg-blue-900 md:order-first" data-sal="slide-up" data-sal-delay="100">
        <img
          src={imgIntegrations}
          alt="Integrations"
          className="filter drop-shadow-xl"
          data-sal="slide-up"
          data-sal-delay="300"
        />
      </TwoColCard>
    </SectionTwoCols>
  </>
)

export default PlatformFeaturesList
