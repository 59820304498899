import React from "react"

// UI
import Headline from "~ui/Headline"
import SectionTwoCols, { TwoColCard } from "~components/SectionTwoCols"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faBuildings, faUserGroup } from "@fortawesome/pro-thin-svg-icons"

const PlatformTrust: React.FC = () => (
  <SectionTwoCols>
    <TwoColCard className="bg-gray-50 dark:bg-gray-900" data-sal="slide-up">
      <div className="mb-8 md:mb-12">
        <Icon icon={faUserGroup} size="4x" className="text-blue-500" />
      </div>
      <Headline level={3} className="xl:text-6xl mb-6 md:mb-8">
        360&deg; Customer View
      </Headline>
      <p className="md:text-lg">
        Upsell, cross-sell, and generally delight customers while decreasing servicing costs.
      </p>
    </TwoColCard>
    <TwoColCard className="bg-gray-50 dark:bg-gray-900" data-sal="slide-up" data-sal-delay="100">
      <div className="mb-8 md:mb-12">
        <Icon icon={faBuildings} size="4x" className="text-blue-500" />
      </div>
      <Headline level={3} className="xl:text-6xl mb-6 md:mb-8">
        Enterprise-ready
      </Headline>
      <p className="lg:text-lg">
        Safeguard data in a secure, immutable database with comprehensive compliance certifications and attestations,
        including PCI DSS and SOC 2 Type 2.
      </p>
    </TwoColCard>
  </SectionTwoCols>
)

export default PlatformTrust
