import React from "react"

// UI
import SectionThreeCols, { ThreeColCard } from "~components/SectionThreeCols"
import { faStars, faShield, faHeadset } from "@fortawesome/pro-thin-svg-icons"

const PlatformTopFeatures: React.FC = () => (
  <SectionThreeCols title="Fully Configurable &amp; Lightning Fast" className="mb-20 lg:mb-40">
    <ThreeColCard title="Next-gen constructs" icon={faStars} data-sal="slide-up">
      <p>Take new product constructs to market fast and service them in innovative and compliant ways.</p>
    </ThreeColCard>
    <ThreeColCard title="Compliance guardrails" icon={faShield} data-sal="slide-up" data-sal-delay="100">
      <p>
        Reduce compliance risk with automations and an immutable ledger that can play back all events in the account
        life cycle.
      </p>
    </ThreeColCard>
    <ThreeColCard title="Operational support" icon={faHeadset} data-sal="slide-up" data-sal-delay="200">
      <p>Stand up new ops teams with guidance from dedicated experts. Optimize hiring, workflows, and more.</p>
    </ThreeColCard>
  </SectionThreeCols>
)

export default PlatformTopFeatures
