import React from "react"

// UI
import Headline from "~ui/Headline"
import SectionCallout from "~components/SectionCallout"

import avatarImg from "~images/use-case/avatar-dash-fi.jpg"

const PlatformTestimonial: React.FC = () => (
  <SectionCallout className="text-left md:text-center mb-20 md:mb-40">
    <Headline
      level={5}
      className="text-white mb-6 md:mb-10 leading-tight lg:leading-snug"
      textSize="text-lg md:text-2xl"
    >
      &quot;Canopy makes launching credit products fast and easy. If you want to create an innovative product, it’s
      actually quite difficult if you go at it alone. Canopy was critical in converting AdCard from a debit card to a
      charge card. I love them.&quot;
    </Headline>
    <div className="space-x-6">
      <div className="inline-block align-middle overflow-hidden rounded-full w-16 h-16 bg-blue-400">
        <img src={avatarImg} alt="Zack Johnson - FunnelDash" />
      </div>
      <div className="inline-block align-middle text-left text-white">
        <strong>Zach Johnson</strong>
        <br /> Founder, dash.fi
      </div>
    </div>
  </SectionCallout>
)

export default PlatformTestimonial
